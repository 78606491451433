import wretch from 'wretch'

const BASE_URL = process.env.VUE_APP_AT_API_BASE || 'https://anonymoustexting.ai-gate.com'

/**
 * @query user=&pass=&id=&limit=&offset=
 * user - username
 * pass - password
 * id - ???
 * limit - how many results
 * offset - skip how many results
 * @response {
 *   "success": true,
 *   "data": {
 *     "logs": [
 *       {
 *         "target":"+14582324300",
 *         "service":"+17206192614",
 *         "logid":20009364,
 *         "when":1632485580,
 *         "scenario":"atext",
 *         "direction":"outgoing",
 *         "status":"sent",
 *         "text":"Hello mate slut",
 *         "mime":"text\/plain",
 *         "answer":" ",
 *         "url":""
 *       }
 *     ],
 *     "user": {
 *       "credits": 365,
 *       "trial": false,
 *       "guest": false,
 *       "ads_count": 0
 *     },
 *     "system": {
 *       "update": false,
 *       "message":""
 *     }
 *   }
 * }
 * @type {string}
 */
const LIST_CONVERSATIONS = '/script25/mpr/getlogs.php'

/**
 * @query user=&pass=&target=&id=&limit=&offset=&direction=after&sort=DESC
 * user - username
 * pass - password
 * target - target number
 * id - return messages after this message id
 * limit - how many results
 * offset - skip how many results
 * direction - 'after'
 * sort - 'DESC'
 * @response {
 *   "success":true,
 *   "data":{
 *     "logs":[
 *       {
 *         "target":"+14582324300",
 *         "service":"+12065351609",
 *         "logid":20009390,
 *         "when":1632490282,
 *         "scenario":"atext",
 *         "direction":"outgoing",
 *         "status":"sent",
 *         "text":"asdfasdf",
 *         "mime":"text\/plain",
 *         "answer":" ",
 *         "url":""
 *       }
 *     ]
 *   }
 * }
 * @type {string}
 */
const LIST_MESSAGES = '/script25/mpr/getlogstarget.php'

/**
 * @method POST
 * @body
 * user - username
 * pass - password
 * to - target phone number
 * number - optional source phone number
 * message - message to send
 * @response {
 *   'success': true,
 *   'data': {
 *     'target': '+14582324300',
 *     'service': '+12065351609',
 *     'logid': 20009383,
 *     'when': 1632485580,
 *     'scenario': 'atext',
 *     'direction': 'outgoing',
 *     'status': 'sent',
 *     'text': 'Hello mpr',
 *     'mime': 'text\/plain'
 *   }
 * }
 * @type {string}
 */
const SEND_MESSAGE = '/script25/mpr/send.php'

/**
 * @method POST
 * @body
 * user - username
 * pass - password
 * to - target phone number
 * fileToUpload - file
 * type - image for image
 * @response {
 *   'success': true,
 *   'data': {
 *     'target': '+14582324300',
 *     'service': '+12065351609',
 *     'logid': 20009383,
 *     'when': 1632485580,
 *     'scenario': 'atext',
 *     'direction': 'outgoing',
 *     'status': 'sent',
 *     'text': 'Hello mpr',
 *     'mime': 'text\/plain'
 *   }
 * }
 * @type {string}
 */
const SEND_MMS = '/script25/mpr/sendmms.php'

/**
 * @method POST
 * @body
 * user - username
 * pass - password
 * to - target phone number
 * @response {
 *   'success': true
 * }
 * @type {string}
 */
const CHANGE_NUMBER = '/script25/mpr/changenumber.php'

/**
 * @method POST
 * @body
 * user - username
 * pass - password
 * target - target phone number
 * @response {
 *   'success': true
 * }
 * @type {string}
 */
const DELETE_CONVERSATION = '/script25/mpr/delconversation.php'

/**
 * @body
 * to - target phone
 * @response { "result": [ { "message" : "This number is already blocked." } ]}
 * @type {string}
 */
const BLOCK_NUMBER = '/script25/mpr/blocknumber.php'

/**
 * @body
 * code - activation code
 * @response { "result": [ { "message" : "This number is blocked." } ]}
 * @type {string}
 */
const ACTIVATE_BLOCK_NUMBER = '/script25/mpr/activateblocknumber.php'

const api = wretch()
  .url(BASE_URL)

/**
 * @typedef {Object} Message
 * @property {String} target remote number
 * @property {String} service own number
 * @property {int} logid message id
 * @property {int} when date of the message in timestamp
 * @property {String} scenario ???
 * @property {'outgoing'|'incoming'} direction sent or received
 * @property {'sent'|'no-answer'|'completed'|'delivered'|'active'|'hidden'|'error'|'profanity'} status
 * @property {String} text the text content of the message
 * @property {String} mime mime type of the text content
 * @property {String} answer
 * @property {String} url
 */

/**
 * @typedef {Object} UserInfo
 * @property {int} credits remaining credits
 * @property {boolean} trial true if trial account
 * @property {boolean} guest true if guest account
 * @property {int} ads_count how often to show interstitial ad (for mobile app)
 */

/**
 * @typedef {Object} SystemInfo
 * @property {boolean} update ???
 * @property {String} message ???
 */

/**
 * @param {String} user username
 * @param {String} pass password
 * @param {int} id ???
 * @param {int} limit how many results to return
 * @param {int} offset how many results to skip
 * @return {Promise<{success: boolean, data: {logs: Message[], user: UserInfo, system: SystemInfo}}>}
 */
export const listConversations = async ({ user, pass, id = 0, limit = 1000, offset = 0 }) =>
  api.url(LIST_CONVERSATIONS)
    .formData({ user, pass, id, limit, offset })
    .post()
    .json()

/**
 * @param {String} user username
 * @param {String} pass password
 * @param {String} target target number
 * @param {int} id start after message id
 * @param {int} limit how many results to return
 * @param {int} offset how many results to skip
 * @param {String} direction
 * @param {String} sort
 * @return {Promise<{success: boolean, data: {logs: Message[], user: UserInfo, system: SystemInfo}}>}
 */
export const listMessages = async ({
  user,
  pass,
  target,
  id = 0,
  limit = 100,
  offset = 0,
  direction = 'before',
  sort = 'DESC'
}) =>
  api.url(LIST_MESSAGES)
    .formData({ user, pass, target, id, limit, offset, direction, sort })
    .post()
    .json()

/**
 * @param {String} user username
 * @param {String} pass password
 * @param {String} to phone number to send message to
 * @param {String} number optional own number to use
 * @param {String} message message to send
 * @return {Promise<{success: boolean, data: Message }>}
 */
export const sendMessage = async ({ user, pass, to, number = '', message, profanity = 0 }) =>
  api.url(SEND_MESSAGE)
    .formData({ user, pass, to, number: number || '', message, profanity })
    .post()
    .json()

/**
 * @param {String} user username
 * @param {String} pass password
 * @param {String} to phone number to send message to
 * @param {File} fileToUpload file
 * @param {'image'} type
 * @return {Promise<{success: boolean, data: Message}>}
 */
export const sendMMS = async ({ user, pass, to, fileToUpload, type = 'image' }) =>
  api.url(SEND_MMS)
    .formData({ user, pass, to, fileToUpload, type })
    .post()
    .json()

/**
 * @param {String} user username
 * @param {String} pass password
 * @param {String} to target phone number to replace own associated number
 * @returns {Promise<{success: boolean}>}
 */
export const changeNumber = async ({ user, pass, to }) =>
  api.url(CHANGE_NUMBER)
    .formData({ user, pass, to })
    .post()
    .json()

/**
 * @param {String} user username
 * @param {String} pass password
 * @param {String} target target phone number to delete conversation with
 * @returns {Promise<{success: boolean}>}
 */
export const deleteConversation = async ({ user, pass, target }) =>
  api.url(DELETE_CONVERSATION)
    .formData({ user, pass, target })
    .post()
    .json()

export const blockNumber = async ({ user, pass, to }) =>
  api.url(BLOCK_NUMBER)
    .formUrl({ user, pass, to })
    .post()
    .json()

export const sendVerificationCode = async ({ code, to }) =>
  api.url(ACTIVATE_BLOCK_NUMBER)
    .formUrl({ code, to })
    .post()
    .json()
